import React, { createContext, useState, useContext } from 'react'
import translations from './translations.json' // Ruta al archivo JSON

const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('es') // Idioma predeterminado

  const translate = (key) => {
    const keys = key.split('.')
    return keys.reduce((obj, currentKey) => (obj ? obj[currentKey] : null), translations[language])
  }

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => {
  const context = useContext(LanguageContext)

  if (!context) {
    //throw new Error('useLanguage must be used within a LanguageProvider')
    throw new Error('Ha habido un error. Inténtalo nuevamente o más tarde.')
  }

  return context
}