import { LogErrors } from './../../LogErrors'

import { UpdateSessionInfo } from './../../DL/Sessions'
import { GetLearningInstruments as DLGetLearningInstruments, GetActivity as DLGetActivity } from './../../DL/Student/Instruments'
import { SaveInstrumentToShow } from './../Navigation'


const GetLearningInstruments = async (session) => {
    try {
        if ((session.navigation === undefined || session.navigation === null) || (session.navigation.course === undefined || session.navigation.course === null) || (session.navigation.instrument === undefined || session.navigation.instrument === null)) {
            // Mostrar mensaje de que no se pudo obtener el contenido del instrumento a mostrar
            return { success: false, message: '' }
        }

        //Validar que exista la info del usuario en el session storage
        if (!sessionStorage.getItem('userData')) {
            return { success: false, message: '' } //En este caso borraron los datos; así que le muestro mensaje indicando que su sesión ha expirado.
        }
    
        //Obtener la info del usuario desde el session storage
        const userData = JSON.parse(sessionStorage.getItem('userData'))

        //Comprobar varias propiedades para ver si está correcta la info del usuario
        if (userData.navigation === undefined || userData.navigation === null) {
            return { success: false, message: '' } //Mensaje indicando que su sesión ha expirado.
        }
        
        
        let response

        if (userData.navigation.instrument.type === 1) {
            //response = await DLGetLearningInstruments(session.token, session.navigation)
        } else if (userData.navigation.instrument.type === 2) {
            response = await DLGetLearningInstruments(session.token, session.navigation)
        } else if (userData.navigation.instrument.type === 3) {
            response = await DLGetActivity(session.token, session.navigation)
        }

        
        if (response.success) {
            // Quitar el acceso rápido o directo al instrumento aprendizaje o evaluación
            if (userData.navigation.course.goToQuickAccessInstrument) {
                userData.navigation.course.goToQuickAccessInstrument = false
                await UpdateSessionInfo(userData)
            }
        
            return { success: true, data: response.data }
        } else {
            return { success: false, message: response.message }
        }
    } catch (error) {
        await LogErrors('GetLearningInstruments - BL Instruments', error)
        return { success: false, message: '' }
    }
}

const GetAndSaveInstrumentToShow = async (session) =>{
    try {
        let instrument

        for (const block of session.courses.blocks) { 
            const tmpInstrument = block.items.find((item) => item.id === session.courses.quickAccess.id)

            if (tmpInstrument) {
                instrument = tmpInstrument // Retorna el primer objeto que coincide
                break
            }
            
            instrument = null // Retorna null si no se encuentra
        }

        if (instrument === null) {
            return { success: false, message: 'No ha sido posible mostrar el recurso seleccionado.' }
        } else {
            // const instrumentData = {
            //     id: instrument.id,
            //     name: instrument.name,
            //     type: instrument.type
            // }

            //console.log("instrumentData: " + JSON.stringify(instrumentData))
    
            const response = await SaveInstrumentToShow(session, instrument)

            if (response.success) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response.message }
            }
        }
    } catch (error) {
        await LogErrors('GetAndSaveInstrumentToShow - BL Instruments', error)
        return { success: false, message: '' }
    }
}

const GetInstrumentName = async (session) =>{
    try {
        let instrument

        for (const block of session.courses.blocks) { 
            const tmpInstrument = block.items.find((item) => item.id === session.courses.quickAccess.id)

            if (tmpInstrument) {
                instrument = tmpInstrument.name // Retorna el primer objeto que coincide
                break
            }
            
            instrument = null // Retorna null si no se encuentra
        }

        if (instrument === null) {
            return { success: false, message: '' } //No ha sido posible mostrar el nombre del recurso en el botón Continuar donde me quedé
        } else {
            return { success: true, data: instrument }
        }
    } catch (error) {
        await LogErrors('GetInstrumentName - BL Instruments', error)
        return { success: false, message: '' }
    }
}

/**
 * Función que filtra los items de los bloques en base a un término de búsqueda.
 * @param {Array} blocks - Lista de bloques (propiedad blocks del JSON courseInstruments).
 * @param {string} searchTerm - Término de búsqueda proporcionado por el usuario.
 * @param {number} maxResults - Número máximo de resultados que deben retornarse.
 * @returns {Array} Lista de items coincidentes con el término de búsqueda.
 */
const GetSearchResults = async (blocks, searchTerm, maxResults) => {
    try {
        if (!blocks || !searchTerm.trim()) {
            return { success: true, data: [] }
        }

        const lowerCaseSearchTerm = searchTerm.toLowerCase()

        // Extrae los items de todos los bloques y filtra por el término de búsqueda
        const matchingItems = blocks.flatMap((block) =>
            block.items.filter((item) =>
                item.name.toLowerCase().includes(lowerCaseSearchTerm)
            )
        )

        // Limitar el número de resultados al máximo especificado
        const results = matchingItems.slice(0, maxResults)

        if (results.length === 0) {
            return { success: true, data: [] }
        } else {
            return { success: true, data: results}
        }
    } catch (error) {
        await LogErrors('GetSearchResults - BL Instruments', error)
        return { success: false, message: '' }
    }
}


//----- Funciones personalizadas de Quizz ------//

// Función para aleatorizar un array
const shuffleArray = (array) => array.sort(() => Math.random() - 0.5)

const GetRandomizedQuestions = async (questions) => {
    try {
        //Completar función para aleatorizar las preguntas y sus opciones
        if (questions.length === 0) {
            return { success: false, message: '' }
        } else {
            // Aleatorizar preguntas
            const shuffledQuestions = shuffleArray(questions)
            
            // Aleatorizar opciones dentro de cada pregunta
            shuffledQuestions.forEach((question) => {
                question.options = shuffleArray(question.options)
            })

            return { success: true, data: shuffledQuestions }
        }
    } catch (error) {
        await LogErrors('GetRandomizedQuestions - BL Instruments', error)
        return { success: false, message: '' }
    }
}

const GetCorrectAnswers = async (questions, selectedAnswers) => {
    try {
        if (questions.length === 0) {
            return { success: false, message: 'Hubo un error y no es posible evaluar tus respuestas.' }
        } else {
            let countCorrect = 0
            
            questions.forEach((question, index) => {
                const correctOption = question.options.find(option => option.isCorrect)
                
                if (selectedAnswers[index] === correctOption?.text) {
                    countCorrect++
                }
            })

            return { success: true, data: countCorrect }
        }
    } catch (error) {
        await LogErrors('GetCorrectAnswers - BL Instruments', error)
        return { success: false, message: '' }
    }
}

//----- Funciones personalizadas de GetQuiz ------//



//----- Funciones personalizadas de Challenge ------//

const GetRandomizedOptions = async (options) => {
    try {
        //Completar función para aleatorizar las preguntas y sus opciones
        if (options.length === 0) {
            return { success: false, message: '' }
        } else {
            // Aleatorizar preguntas
            const shuffledOptions = shuffleArray(options)

            return { success: true, data: shuffledOptions }
        }
    } catch (error) {
        await LogErrors('GetRandomizedOptions - BL Instruments', error)
        return { success: false, message: '' }
    }
}

//----- Funciones personalizadas de Challenge ------//



//----- Funciones personalizadas de Activities ------//

// const GetActivity = async (session) => {
//     try {
//         if ((session.navigation === undefined || session.navigation === null) || (session.navigation.course === undefined || session.navigation.course === null) || (session.navigation.instrument === undefined || session.navigation.instrument === null)) {
//             // Mostrar mensaje de que no se pudo obtener el contenido del instrumento a mostrar
//             return { success: false, message: '' }
//         }

//         //Validar que exista la info del usuario en el session storage
//         if (!sessionStorage.getItem('userData')) {
//             return { success: false, message: '' } //En este caso borraron los datos; así que le muestro mensaje indicando que su sesión ha expirado.
//         }
    
//         //Obtener la info del usuario desde el session storage
//         const userData = JSON.parse(sessionStorage.getItem('userData'))

//         //Comprobar varias propiedades para ver si está correcta la info del usuario
//         if (userData.navigation === undefined || userData.navigation === null) {
//             return { success: false, message: '' } //Mensaje indicando que su sesión ha expirado.
//         }
        
//         const response = await DLGetActivity(session.token, session.navigation)
        
//         if (response.success) {
//             // Quitar el acceso rápido o directo al instrumento aprendizaje o evaluación
//             if (userData.navigation.course.goToQuickAccessInstrument) {
//                 userData.navigation.course.goToQuickAccessInstrument = false
//                 await UpdateSessionInfo(userData)
//             }
        
//             return { success: true, data: response.data }
//         } else {
//             return { success: false, message: response.message }
//         }
//     } catch (error) {
//         await LogErrors('GetActivity - BL Instruments', error)
//         return { success: false, message: '' }
//     }
// }

//----- Funciones personalizadas de Activities ------//

export { GetLearningInstruments, GetCorrectAnswers, GetAndSaveInstrumentToShow, GetInstrumentName, GetSearchResults, GetRandomizedQuestions, GetRandomizedOptions }; //GetActivity