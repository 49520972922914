/**
 * Envía un error a la API de registro.
 * @param {string} functionName - Nombre y ubicación de la función donde ocurrió el error.
 * @param {string} error - Detalles del error.
 */
const LogErrors = async (functionName, error) => {
    try {
        // await fetch('URL', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //         functionName,
        //         error: typeof error === 'string' ? error : error.toString(), // Convertir el error a string si es necesario
        //         //timestamp: new Date().toISOString(),
        //     })
        // })

        console.log(`Error en ${functionName}: ${error}`)
    } catch (logError) {
        //console.error('Error al registrar el error:', logError)
        console.error('Ocurrió un error inesperado.' + logError)
    }
}

export { LogErrors };