import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../utilities/LogErrors'
import { useLanguage } from './../../utilities/LanguageContext'

import { ValidateSession } from './../../utilities/BL/Sessions'

//Componentes personalizados del proyecto
import { Blocks } from './Blocks'

//Componentes comunes generales
import { Header } from './../Header/Header'
import { Loading } from './../Common/Loading'
import { SearchInput } from './SearchInput'

//Funciones personalizadas del proyecto
import { GetChoosenCourse, ResetChoosenCourse } from './../../utilities/BL/Student/Courses'
import { ResetInstrumentToShow } from './../../utilities/BL/Navigation'
import { GetAndSaveInstrumentToShow, GetInstrumentName } from './../../utilities/BL/Student/Instruments'
import { GoToBack } from './../Common/GoToBack'


const Course = () => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [session, setSession] = useState({})

    const [course, setCourse] = useState({})
    const [courseInstruments, setCourseInstruments] = useState({})
    const [quickaccessName, setQuickaccessName] = useState(null)
    const [isObjectiveVisible, setIsObjectiveVisible] = useState(false)


    const GoToNextView = async () => {
        try {
            const response = await GetAndSaveInstrumentToShow(session)

            if (response.success) {
                navigate('/viewer')
            } else {
                //Mostrar mensaje de que no se puedo obtener la info o el error
                console.log(response.message) //Mensaje temporal
            }
        } catch (error) {
            await LogErrors('GoToNextView - Course', error)
        }
    }

    const LoadPage = async (sessionInfo) => {
        try {
            const currentSessionInfo = await ResetInstrumentToShow(sessionInfo)
            
            if (currentSessionInfo.success) {
                const newSessionInfo = await ResetChoosenCourse(currentSessionInfo.data)

                if (newSessionInfo.success) {
                    setSession(newSessionInfo.data)

                    const response = await GetChoosenCourse(newSessionInfo.data)
                    
                    if (response.success) {
                        if (newSessionInfo.data.navigation.course.goToQuickAccessInstrument) {
                            const tmpResponse = await GetAndSaveInstrumentToShow(newSessionInfo.data)

                            if (tmpResponse.success) {
                                navigate('/viewer')
                            } else {
                                //Mostrar mensaje de que no se puedo obtener la info o el error
                                console.log(tmpResponse.message) //Mensaje temporal
                            }
                        } else {
                            // const item = data.items.find((item) => item.id === id)
                            setCourse(newSessionInfo.data.navigation.course)
                            setCourseInstruments(response.data)
                            
                            const tmpQuickaccessName = await GetInstrumentName(newSessionInfo.data)

                            if (tmpQuickaccessName.success) {
                                setQuickaccessName(tmpQuickaccessName.data)
                            } else {
                                //Mostrar mensaje de que no se puedo obtener la info o el error
                                console.log(tmpQuickaccessName.message) //Mensaje temporal
                            }
                        }
                    } else {
                        //Mostrar mensaje de que no se puedo obtener la info o el error
                        console.log(response.message) //Mensaje temporal
                    }
                } else {
                    //Mostrar mensaje de que no se puedo obtener la info o el error
                    console.log(newSessionInfo.message) //Mensaje temporal
                }
            } else {
                //Mostrar mensaje de que no se puedo obtener la info o el error
                console.log(currentSessionInfo.message) //Mensaje temporal
            }
        } catch (error) {
            await LogErrors('LoadPage - Course', error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        async function Validate() {
            const tmpSession = await ValidateSession()
            setSession(tmpSession.data)
    
            if (tmpSession.success) {
                if (tmpSession.data.user.isConfirmed) {
                    await LoadPage(tmpSession.data)
                } else {
                    navigate('/confirm')
                }
            } else {
                navigate('/')
            }
        }
        Validate()
    },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar

    const ToggleCourseObjective = async () => {
        try {
            setIsObjectiveVisible(!isObjectiveVisible)
        } catch (error) {
            await LogErrors('ToggleCourseObjective - Course', error)
        }
    }


    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Header theme={session.user.theme} />

                    <main className='Container Course'>
                        <GoToBack url={'/dashboard'} />

                        {course && courseInstruments && (
                            <>
                                <div className='Course-Info'>
                                    <div className='Course-Text'>
                                        <p className='Course-Name'>{`${course.name} nivel ${course.level === 1 ? translate('labels.course.levels.one') : translate('labels.course.levels.two')}`}</p>
                                        <p className='Course-Type'>{course.type === 1 ? translate('labels.dashboard.tiles.examType.certification') : translate('labels.dashboard.tiles.examType.custom')}</p>
                                        {courseInstruments.objective && courseInstruments.objective !== '' && (
                                            <>
                                                <button type='button' className='Course-Objective-Button' onClick={ToggleCourseObjective}>{isObjectiveVisible ? 'Ocultar objetivo del curso' : 'Ver objetivo del curso'}</button>
                                                
                                                <div className={`Course-Objective ${isObjectiveVisible ? 'Course-Objective-Show' : ''}`}>
                                                    <p className='Course-Objective-Title'>Objetivo del curso</p>
                                                    <p className='Course-Objective-Text'>{courseInstruments.objective}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                {quickaccessName && (
                                    <>
                                        <p className='Course-Title'>{translate('labels.course.continueLegend')}</p>
                                        
                                        <button type='button' className='Course-Button' onClick={() => GoToNextView()}>
                                            <span className='Course-Button-Text'>{quickaccessName}</span>
                                        </button>
                                    </>
                                )}

                                <p className='Course-Title'>{translate('labels.course.topicsTitle')}</p>
                                {/* <SearchInput placeholder={'Buscar tema...'} customClassName={'Course-Search'} session={session} indexExam={index} setSession={setSession} /> */}
                                <SearchInput placeholder={`${translate('labels.course.searchInput')}`} session={session} courseInstruments={courseInstruments} />

                                <div className='Course-Topics'>
                                    <Blocks blocks={courseInstruments.blocks} session={session} quickaccessName={quickaccessName} />
                                    {/*<Topics topics={course.topics} indexCourse={course.indexCourse} session={session} setSession={setSession} result={result} />*/} {/*isFirstTimeToTakeExam={isFirstTimeToTakeExam} setIsFirstTimeToTakeExam={setIsFirstTimeToTakeExam}*/}
                                </div>
                            </>
                        )}
                    </main>
                </>
            )}
        </>
    )  
}

export { Course };