import { React, useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


//--- Estilos e íconos de Bootstrap ---//
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './styles/styles.css'
//--- Estilos e íconos de Bootstrap ---//


//--- Página login ---//
import { Login } from './components/Login/Login'
//--- Página login ---//

//--- Páginas del aspirante ---//
import { Dashboard } from './components/Dashboard/Student/Dashboard'
import { Confirm } from './components/Confirm/Confirm'
import { Course } from './components/Course/Course'
import { Viewer } from './components/Viewer/Viewer'
import { Results } from './components/Results/Results'
//--- Páginas del aspirante ---//



function App() {
  //Propiedades:
  //activeElementClass = Establece los estilos que lleva el Tile al establecerlo como activo
  //isFavorite = Establece si el elemento debe mostrarse visualmente marcado como favorito

  //Educador
  // const tileEducatorElements = [
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Agendar evaluación al grupo "Excel Academic 2 A"', activeElementClass: 'DegradedExcel' },
  //   { tileClassName: 'ms-3', tileIconClass: 'bi bi-graph-up-arrow', tileText: ' Ver resultados del grupo "Excel Academic 2 A"' },
  //   { tileClassName: 'ms-3', tileIconClass: 'bi bi-person-add', tileText: ' Agregar alumnos al grupo "Excel Academic 2 A"' }
  // ]

  // const tileEducatorElements1 = [
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Agendar evaluación', isFavorite: true },
  //   { tileClassName: 'ms-3', tileIconClass: 'bi bi-graph-up-arrow', tileText: ' Informe en tiempo real', isFavorite: true },
  //   { tileClassName: 'ms-3', tileIconClass: 'bi bi-person-add', tileText: ' Importar alumnos', isFavorite: true }
  // ]

  // const tileEducatorSMElements = [
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Administrar evaluaciones ' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Administrar grupos' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Administrar a mis alumnos' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Progreso de mis alumnos' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Ver resultados' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Informe en tiempo real' },
  //   { tileIconClass: 'bi bi-file-text', tileText: ' Ver temarios' }
  // ]




  // Obtén los enlaces para un idioma específico, por ejemplo, "spa"
  //const infoCourse = Course.english
  
  // const changeLanguage = async (language = null, isLoginPage = false) => {
  //   try {
  //     const newSettings = await getSettings(language, isLoginPage) // Esperar a que getSettings termine
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // /**
  //  * Función para obtener la configuración, una vez que termine de cargarse el componente
  //  */
  // useEffect(() => {
  //   // Cargar configuración al montar el componente (carga inicial)
  //   changeLanguage(null, true)
  // },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar

  //const [activeComponent, setActiveComponent] = useState('Login') // Estado para controlar el componente activo
  const [current, setCurrent] = useState({})
  const [result, setResult] = useState({})
  const [showQuickAccess, SetShowQuickAccess] = useState({})
  //const [isFirstTimeToTakeExam, setIsFirstTimeToTakeExam] = useState(true)
  
  const [theme, setTheme] = useState('')
  const [fontSize, setFontSize] = useState('')

  //--- Funciones para mostrar u ocultar los componentes principales ---//
  // const ShowLoginView = () => {
  //   setActiveComponent('Login')
  // }

  // const ShowDashboardView = () => {
  //   setActiveComponent('Dashboard')
  // }

  // const ShowCourseView = () => {
  //   setActiveComponent('Course')
  // }

  // const ShowViewerView = () => {
  //   setActiveComponent('Viewer')
  // }

  // const ShowResultsView = () => {
  //   setActiveComponent('Results')
  // }
  //--- Funciones para mostrar u ocultar los componentes principales ---//

  const SetFontSizeToWeb = (fontSizeSelected) => {
    try {
      setFontSize(fontSizeSelected)
      
      document.getElementById('root').classList.remove('SmallFont','MediumFont','BigFont')
      document.getElementById('root').classList.add(fontSizeSelected)

      //Función para mandar a guardar el tamaño de fuente a la API
      //SaveWebFontSize(fontSizeSelected)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    document.getElementById('root').classList.add('BodyComplete')
  }, [])


  try {
    return (
      <Router>
        <Routes>
          {/* Página login */}
          <Route
            path='/'
            element={<Login />}
          />
          
          {/* Páginas Ztudiante */}
          <Route
            path='/confirm'
            element={<Confirm />}
          />
          <Route
            path='/dashboard'
            element={<Dashboard />}
          />
          <Route
            path='/course'
            element={<Course />}
          />
          <Route
            path='/viewer'
            element={<Viewer />}
          />
          <Route
            path='/results'
            element={<Results theme={theme} setTheme={setTheme} fontSize={fontSize} setFontSize={SetFontSizeToWeb} />}
          />
        </Routes>
      </Router>
    )
  } catch (error) {
      console.log(error)
  }
}

export default App;