//Funciones personalizadas del proyecto
//import { SetTopicToShow, SetActivityToShow, SetIndexCourseAndTopic } from '../../../utilities/BL/Students'

//Componentes comunes generales
import { Button } from './../../Common/Button'

//Componentes del proyecto
import { Course } from './Course'
import { ApprovedCourse } from './ApprovedCourse'


const Tile = ({ elements, ...props }) => {
    const { nextView, session, setSession } = props


    return (
        <>
            {/* Validar que el arreglo tenga elementos por imprimir */}
            {elements !== undefined && elements.length > 0 && (
                //Recorrer los elementos del arreglo
                elements.map((element, index) => (
                    element && (
                        <div key={index} className={`Tiles HideMoveButton ${element.tileClassName ? element.tileClassName : ''}`}>
                            {/* Botón mover */}
                            <Button className={'Tiles-ButtonMoveTile'} iconClass={'bi bi-three-dots-vertical'} />

                            {element.type === 1 ? (
                                // Si el tipo es curso
                                <Course index={index} element={element} session={session} setSession={setSession} />
                            ) : (
                                // Sino, si el tipo es curso aprobado
                                <ApprovedCourse element={element} session={session} setSession={setSession} />
                            )}

                            

                            {/* {element.type.toLowerCase() === 'teachingMaterial'.toLowerCase() ? (
                                <div className={`Tiles-StudentTile--L ${index === 0 && element.activeElementClass && element.activeElementClass !== '' ? element.activeElementClass + ' ActiveTile' : ''}`} onMouseOver={ShowMoveIcon} onMouseOut={HideMoveIcon}> {/* onClick={() => SetCourseToShow(element.indexCourse)} / }
                                    <div className='Tile-Info' onClick={() => SetCourseToShow(element.indexCourse)}>
                                        {element.tileIconClass && element.tileIconClass !== '' && (
                                            <figure className='Tile-Figure'>
                                                <i className={`Tile-Icon ${element.tileIconClass}`}></i>
                                            </figure>
                                        )}
                                        
                                        <div className='Tile-ContainerText'>
                                            <span className='Tile-Text'>{element.typeCourse}</span>
                                            <span className='Tile-Text'>{element.nameCourse}</span>
                                        </div>
                                    </div>

                                    <button type='button' className='Tile-Button-Highlight' onClick={() => ShowTopic(element.indexCourse, element.indexExam, element.indexTopic)}>Ir a donde me quedé</button> {/* Tile-Button-Highlight-Border /}
                                    <button type='button' className='Tile-Button-Transparent' onClick={() => SetCourseToShow(element.indexCourse)}>Ver curso</button>

                                    {/* Botón /}
                                    {/* <i className='bi bi-box-arrow-up-right Tile-Icon-GoTo' onClick={() => SetCourseToShow(element.indexCourse)}></i> /}
                                </div>
                            ) : (
                                <>
                                    {/* //Falta completar esto /}
                                    {element.type.toLowerCase() === 'activity'.toLowerCase() && (
                                        <>
                                            <p key={index} onClick={() => ShowActivity(element.indexCourse, element.indexTopic, element.indexActivity)}>activity</p>
                                        </>
                                    )}

                                    {/* //Falta completar esto /}
                                    {element.type.toLowerCase() === 'exam'.toLowerCase() && (
                                        <>
                                            <p key={index} onClick={() => ShowExam()}>exam</p>
                                        </>
                                    )}

                                    {element.type.toLowerCase() === 'course'.toLowerCase() && (
                                        <a className={`Tiles-StudentTile--M--Double ${index === 0 && element.activeElementClass && element.activeElementClass !== '' ? element.activeElementClass : ''}`} onMouseOver={ShowMoveIcon} onMouseOut={HideMoveIcon} onClick={() => SetCourseToShow(element.indexCourse)}>
                                            <div className='Tile-Info'>
                                                {element.tileIconClass && element.tileIconClass !== '' && (
                                                    <figure className='Tile-Figure'>
                                                        <i className={`Tile-Icon ${element.tileIconClass}`}></i>
                                                    </figure>
                                                )}
                                                
                                                <div className='Tile-ContainerText'>
                                                    <span className='Tile-Text'>{element.typeCourse}</span>
                                                    <span className='Tile-Text'>{element.nameCourse}</span>
                                                </div>
                                            </div>

                                            {/* Botón /}
                                            <i className='bi bi-box-arrow-up-right Tile-Icon-GoTo'></i>
                                        </a>
                                    )}
                                </>
                            )} */}
                        </div>
                    )
                ))
            // ) : (
            //     isResult && (
            //         <div className='Tiles HideMoveButton'>
            //             {/* Botón mover */}
            //             <Button className={'Tiles-ButtonMoveTile'} iconClass={'bi bi-three-dots-vertical'} />
                        
            //             {/* <a className='Tiles-StudentTile--M--Double' onClick={() => navigate(nextView)}>
            //                 <div className='Tile-Info'>
            //                     <figure className='Tile-Figure'>
            //                         <i className='Tile-Icon bi bi-list-check'></i>
            //                     </figure>
                            
            //                     <div className='Tile-ContainerText'>
            //                         <span className='Tile-Text'>Examen Demo</span>
            //                         <span className='Tile-Text'>Certificación</span>
            //                     </div>
            //                 </div>
                            
            //                 <i className='bi bi-box-arrow-up-right Tile-Icon-GoTo'></i>
            //             </a> */}

            //             <a className={'Tiles-StudentTile--M--Triple'} onClick={() => navigate(nextView)}>
            //                 <div className='Tile-Info'>
            //                     <figure className='Tile-Figure'>
            //                         <i className='Tile-Icon bi bi-list-check'></i>
            //                     </figure>
                                
            //                     <div className='Tile-ContainerText'>
            //                         <span className='Tile-Text'>Diplomado</span>
            //                         <span className='Tile-Text'>Secretariado</span>
            //                         <span className='Tile-Text'><i className='bi bi-check-circle-fill'></i> ¡Aprobado!</span>
            //                     </div>
            //                 </div>

            //                 {/* Botón */}
            //                 <i className='bi bi-box-arrow-up-right Tile-Icon-GoTo'></i>
            //             </a>
            //         </div>

            //    )
            )}
        </>
    )
}

export { Tile };