import { LogErrors } from './../LogErrors'
//import { useLanguage } from './../LanguageContext' // Importa el hook

//Funciones comunes
import { ValidateSqlInjection } from './Security'

import { Login as BLLogin } from './../BL/Sessions'


/**
 * Función que valida los datos ingresados, realiza el login, y devuelve resultados.
 * @param {Object} formData Información del formulario { user, password, remember }
 * @returns {Object} Resultado del proceso { success: boolean, errors: Object, data: Object }
 */
const Login = async (formData) => {
    //const { translate } = useLanguage() // Obtén la función de traducción desde el contexto

    try {
        // Validación de los datos del formulario
        const errors = {}

        if (formData.user) {
            if (!/^[A-Za-z0-9]+$/.test(formData.user)) {
                errors.user = 'El usuario contiene caracteres inválidos'
                //errors.user = translate('errorMessages.login.user.invalidCharacter')
            } else if (ValidateSqlInjection(formData.user)) {
                errors.user = 'El usuario que escribiste es inválido'
            }
        } else {
            errors.user = 'El usuario es obligatorio'
        }

        if (formData.password) {
            if (!/^[A-Za-z0-9\@]+$/.test(formData.password)) {
                errors.password = 'La contraseña contiene caracteres inválidos'
            } else if (ValidateSqlInjection(formData.password)) {
                errors.password = 'La contraseña que escribiste es inválida'
            }
        } else {
            errors.password = 'La contraseña es obligatoria'
        }

        // Si hay errores, retornar inmediatamente
        if (Object.keys(errors).length > 0) {
            return { success: false, errors }
        }

        // Llamar a la capa de datos para autenticar al usuario
        const response = await BLLogin(formData.user, formData.password)

        if (response.success) {
            return { success: true, data: response.data } // Retornar la respuesta exitosa
        } else {
            // Manejar errores del backend (por ejemplo, credenciales incorrectas)
            return { success: false, errors: { general: response.message } }
        }
    } catch (error) {
        await LogErrors('Logins BL', error)
        return { success: false, errors: { general: error || 'Ocurrió un error inesperado' } }
    }
}

export { Login };