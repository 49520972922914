import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../utilities/LogErrors'

//Componentes comunes generales
import { ProgressBar } from './../Common/ProgressBar'

//Funciones personalizadas del proyecto
import { SaveInstrumentToShow } from './../../utilities/BL/Navigation'


//Agregar multiidioma


const Blocks = ({ blocks, session, quickaccessName }) => {
    const navigate = useNavigate()

    // Estado para manejar qué bloques están activos
    const [activeBlocks, setActiveBlocks] = useState({})
    // Estado para manejar qué objetivos están visibles
    const [showObjectives, setShowObjectives] = useState({})
    // Referencias para cada bloque
    const blockRefs = useRef([])
    //Estado para rastrear el bloque seleccionado
    const [blockToScroll, setBlockToScroll] = useState(null)


    // Sincroniza el primer bloque al cargar si `quickaccessName` está definido
    useEffect(() => {
        if (quickaccessName === undefined) {
            setActiveBlocks({ 0: true }) // Establece el primer bloque como activo
        }
    }, [quickaccessName])

    useEffect(() => {
        if (blockToScroll !== null && blockRefs.current[blockToScroll]) {
            blockRefs.current[blockToScroll].scrollIntoView({
                behavior: 'smooth', // Animación suave
                block: 'start', // Alinear al inicio del bloque
            })
            setBlockToScroll(null) // Restablecer el estado después del desplazamiento
        }
    }, [blockToScroll]) // Ejecutar cuando se cambia el bloque a desplazar
    
    const GoToInstrument = async (instrument) => {
        try {
            const response = await SaveInstrumentToShow(session, instrument)

            if (response.success) {
                navigate('/viewer')
            } else {
                // Mostrar mensaje de que no se pudo obtener la info o el error
                console.log('No se pudo obtener la info') // Mensaje temporal
            }
        } catch (error) {
            await LogErrors('GoToInstrument - Blocks', error)
        }
    }

    const ToggleList = (index) => {
        // Actualizar el estado para mostrar el bloque actual
        setActiveBlocks(() => ({
            [index]: !activeBlocks[index] // Cambia el estado solo para este bloque
        }))
        setBlockToScroll(index) // Marcar el bloque que debe desplazarse
        setShowObjectives(() => ({})) // Oculta todos los objetivos
    }

    const ToggleShowObjective = (index) => {
        setShowObjectives((prevState) => ({
            ...prevState,
            [index]: !prevState[index] // Invierte el estado del objetivo actual
        }))
    }


    return (
        <>
            {blocks !== undefined && blocks.length > 0 && (
                blocks.map((block, index) => (
                    <div key={index} ref={(el) => (blockRefs.current[index] = el)} className={`Blocks ${activeBlocks[index] ? 'Blocks-Show' : ''}`} onDoubleClick={() => ToggleList(index)}>
                        <div className="Block">
                            <div className="Block-Info">
                                <p className="Block-Title">{block.name}</p>
                                <ProgressBar percentageCompleted={block.percentageCompleted} />
                            </div>

                            {/* Botón para mostrar/ocultar bloque */}
                            <div className="Block-Buttons">
                                <button type="button" className="Block-Buttons-List" onClick={() => ToggleList(index)}>
                                    <i className={`bi bi-caret-${activeBlocks[index] ? 'up' : 'down'}-fill`}></i>
                                </button>
                            </div>
                        </div>

                        <ul className={`Topics ${activeBlocks[index] ? 'Topics-Show' : ''}`}>
                            {/* Botón para mostrar/ocultar el objetivo */}
                            <button type="button" className="Topics-Buttons-Objective" onClick={() => ToggleShowObjective(index)} >
                                {showObjectives[index] ? 'Ocultar objetivo del tema' : 'Ver objetivo del tema'}
                            </button>

                            {/* Cuadro del contenido del objetivo */}
                            <div className={`Topics-Objective ${ showObjectives[index] ? 'Topics-Objective-Show' : '' }`}>
                                <button className="Topics-Objective-Close" onClick={() => ToggleShowObjective(index)} ><i className="bi bi-x-lg"></i></button>
                                
                                <p>Objetivo del tema</p>
                                <p>{block.objective}</p>
                            </div>

                            {/* Listado de temas */}
                            {block.items.map((item, subIndex) => (
                                <li key={subIndex} className={`Topics-${item.type === 1 ? 'Exam' : item.type === 2 ? 'Item' : 'Activity'}`} onClick={() => GoToInstrument(item)}>{item.name}</li>
                            ))}
                        </ul>
                    </div>
                ))
            )}
        </>
    )
}

export { Blocks };




// const Blocks = ({ blocks, session, quickaccessName }) => {
//     const navigate = useNavigate()

//     const [isActive, setIsActive] = useState(false)
//     const [isShowObjective, setIsShowObjective] = useState(false)
    

//     const GoToInstrument = async (instrument) => {
//         try {    
//             const response = await SaveInstrumentToShow(session, instrument)
            
//             if (response.success) {
//                 navigate('/viewer')
//             } else {
//                 //Mostrar mensaje de que no se puedo obtener la info o el error
//                 console.log("No se pudo obtener la info") //Mensaje temporal
//             }
//         } catch (error) {
//             await LogErrors('GoToInstrument - Blocks', error)
//         }
//     }



//     const ToggleList = async (event) => {
//         try {
//             setIsActive(!isActive) // Invertir el estado
//             // const button = event.currentTarget // Obtener el botón clickeado
//             // const parent1 = button.closest('div').parentNode // Buscar Padre del botón
//             // const parent2 = parent1.closest('div').parentNode // Buscar Hijo del botón
//             // parent2.classList.toggle('Blocks-Show') // Agregar/quitar clase
//         } catch (error) {
//             await LogErrors('ToggleList - Blocks', error)
//         }
//     }

//     const ToggleListDoubleClick = async (event) => {
//         try {
//             setIsActive(!isActive) // Invertir el estado
//             //event.currentTarget.classList.toggle('Blocks-Show') // Agregar/quitar clase    
//         } catch (error) {
//             await LogErrors('ToggleListDoubleClick - Blocks', error)
//         }
//     }

//     const ToggleShowObjective = async () => {
//         try {
//             setIsShowObjective(!isShowObjective)
//         } catch (error) {
//             await LogErrors('ToggleShowObjective - Blocks', error)
//         }
//     }


//     return (
//         <>            
//             {blocks !== undefined && blocks.length > 0 && (
//                 blocks.map((block, index) => (
//                     <div key={index} className={`Blocks ${quickaccessName !== undefined && index === 0 ? 'Blocks-Show' : isActive[index] ? 'Blocks-Show' : ''}`} onDoubleClick={ToggleListDoubleClick}>
//                         <div className='Block'>
//                             <div className={'Block-Info'}>
//                                 <p className='Block-Title'>{block.name}</p>
//                                 {/* <ProgressBar percentageCompleted={Block.percentageCompleted} /> */}
//                                 <ProgressBar percentageCompleted={25} />
//                             </div>

//                             {/* Botón para mostrar/ocultar bloque */}
//                             <div className='Block-Buttons'>
//                                 <button type='button' className='Block-Buttons-List' onClick={() => ToggleList(index)}><i className={`bi bi-caret-${isActive ? 'up' : 'down'}-fill`}></i></button>
//                             </div>
//                         </div>

//                         <ul className={`Topics ${quickaccessName !== undefined && index === 0 ? 'Topics-Show' : isActive ? 'Topics-Show' : ''}`}>
//                             {/* Botón para mostrar/ocultar el objetivo */}
//                             <button type='button' className='Topics-Buttons-Objective' onClick={ToggleShowObjective}>{isShowObjective ? 'Ocultar objetivo del tema' : 'Ver objetivo del tema'}</button>

//                             {/* Cuadro del contenido del objetivo  */}
//                             <div className={`Topics-Objective ${isShowObjective ? 'Topics-Objective-Show' : ''}`}>
//                                 <button className='Topics-Objective-Close' onClick={ToggleShowObjective}><i className="bi bi-x-lg"></i></button>
//                                 <p>{block.objective}</p>
//                             </div>
                            
//                             {/* Listado de temas */}
//                             {block.items.map((item, index) => (
//                                 item.type === 1 ? (
//                                     <li key={index} className='Topics-Exam' onClick={() => GoToInstrument(item)}>{item.name}</li>
//                                 ) : (
//                                     item.type === 2 ? (
//                                         <li key={index} className='Topics-Item' onClick={() => GoToInstrument(item)}>{item.name}</li>
//                                     ) : (
//                                         <li key={index} className='Topics-Activity' onClick={() => GoToInstrument(item)}>{item.name}</li>
//                                     )
//                                 )
//                             ))}
//                         </ul>
//                     </div>
//                 ))
//             )}
//         </>
//     )
// }

// export { Blocks }




//Falta completar esto
//Situaciones por abarcar:
//Si es la primera vez, debo poner activo el primer Examen y abrir el listado de este. ✅
//Si viene de otra pantalla y ya tiene current, lo debo devolver a la competencia donde se quedó (Abrir Examen y posicionar en Tema). 
//Si viene de otra pantalla y NO tiene current, debo poner activo el primer o siguiente Examen NO terminado y abrir el listado de este. 



// {
//     "objective": "Texto del objetivo general",
//     "blocks": [
//         {
//             "id": 1,
//             "name": "Nombre de bloque",
//             "objective": "Texto del objetivo",
//             "items": [
//                 {
//                     "id": 1,
//                     "name": "Nombre de tema",
//                     "type": 1
//                 }
//             ]
//         },
//         {
//             "id": 2,
//             "name": "Nombre de bloque",
//             "objective": "Texto del objetivo",
//             "items": [
//                 {
//                     "id": 2,
//                     "name": "Nombre de tema",
//                     "type": 2
//                 },
//                 {
//                     "id": 3,
//                     "name": "Nombre de tema",
//                     "type": 2
//                 },
//                 {
//                     "id": 4,
//                     "name": "Nombre de tema",
//                     "type": 2
//                 },
//                 {
//                     "id": 5,
//                     "name": "Nombre de tema",
//                     "type": 2
//                 }
//             ]
//         },
//         {
//             "id": 3,
//             "name": "Nombre de bloque",
//             "objective": "Texto del objetivo",
//             "items": [
//                 {
//                     "id": 6,
//                     "name": "Nombre de tema",
//                     "type": 3
//                 },
//                 {
//                     "id": 7,
//                     "name": "Nombre de tema",
//                     "type": 1
//                 }
//             ]
//         }
//     ]
// }