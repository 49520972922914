import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

//Funciones comunes generales
import { LogErrors } from './../../utilities/LogErrors'
import { ValidateSqlInjection } from './../../utilities/BL/Security'
import { useLanguage } from './../../utilities/LanguageContext'

//Funciones personalizadas del proyecto
import { GetSearchResults } from './../../utilities/BL/Student/Instruments'
import { SaveInstrumentToShow } from './../../utilities/BL/Navigation'


const SearchInput = ({ placeholder, customClassName = '', session, courseInstruments, maxResultsToShow = 5 }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()

    const [showResults, setShowResults] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    

    /**
     * Maneja la lógica de la búsqueda cuando el usuario escribe.
     * @param {Event} e - Evento onChange del input.
     */
    const ToggleSearchResults = async (e) => {
        try {
            const searchTerm = e.target.value

            // Validar longitud mínima y evitar inyección SQL
            if (searchTerm.trim() && searchTerm.length > 2) {
                if (!ValidateSqlInjection(searchTerm)) {
                    const response = await GetSearchResults(courseInstruments.blocks, searchTerm, maxResultsToShow)

                    if (response.success) {
                        setSearchResults(response.data)
                        setShowResults(true)
                    } else {
                        setShowResults(false)
                        //Mostrar mensaje de error
                        console.log("Error: " + response.message)
                    }
                } else {
                    setShowResults(false)
                }
            } else {
                setShowResults(false)
            }
        } catch (error) {
            await LogErrors('ToggleSearchResults - SearchInput', error)
        }
    }

    /**
     * Navega al instrumento seleccionado.
     * @param {Object} instrument - Instrumento seleccionado.
     */
    const GoToInstrument = async (instrument) => {
        try {
            const response = await SaveInstrumentToShow(session, instrument)

            if (response.success) {
                navigate('/viewer')
            } else {
                // Mostrar mensaje de que no se pudo obtener la info o el error
                console.log('No se pudo obtener la info: ' + response.message) // Mensaje temporal
            }
        } catch (error) {
            await LogErrors('GoToInstrument - SearchInput', error)
        }
    }

    /**
     * Deshabilita la tecla Enter en el input de búsqueda.
     * @param {Event} e - Evento onKeyDown.
     */
    const DisableEnterKey = async (e) => {
        try {
            if (e.key === 'Enter') {
                e.preventDefault()
            }
        } catch (error) {
            await LogErrors('DisableEnterKey - SearchInput', error)
        }
    }
    

    return (
        <div className={customClassName}>
            <input type='search' placeholder={placeholder} className='SearchInput' onChange={ToggleSearchResults} onKeyDown={DisableEnterKey}/>

            <ul id='SearchResults' className={`SearchResults ${showResults ? 'ShowSearchResults' : ''}`}>
                {searchResults && searchResults.length > 0 ? (
                    <>
                        <p className="SearchResults-Title">{translate('labels.search.suggestions')} Sugerencias para tu búsqueda</p>

                        {searchResults.map((item, index) => (
                            <li key={index} className="SearchResults-Item" onClick={() => GoToInstrument(item)}>
                                <span className="SearchResults-Item-Container">
                                    <i className="bi bi-search"></i>
                                    <span className="SearchResults-Text">{item.name}</span>
                                    <span className="SearchResults-Type">{item.type === 3 ? 'Actividad' : item.type === 1 ? 'Examen' : 'Tema'}</span>
                                </span>
                                <i className="bi bi-arrow-right"></i>
                            </li>
                        ))}
                    </>
                ) : (
                    <p className="SearchResults-Title">{translate('labels.search.noResults')} No hay resultados para esta búsqueda</p>
                )}
            </ul>
        </div>
    )
}

export { SearchInput };