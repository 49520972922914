import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../utilities/LogErrors'
import { useLanguage } from './../../utilities/LanguageContext'

//Funciones personalizadas
import { SaveWebTheme, SaveWebFontSize } from './../../utilities/BL/Settings'
import { Logout as BLLogout } from './../../utilities/BL/Sessions'


const Header = ({ text, theme, token }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()
    
    const [theTheme, setTheTheme] = useState(theme)


    const ChangeTheme = async (themeToSave) => {
        try {
            const response = await SaveWebTheme(themeToSave)

            if (response.success) {
                setTheTheme(response.data)
            }
        } catch (error) {
            await LogErrors('ChangeTheme - Header', error)
        }
    }

    const ChangeFontSize = async (fontSizeToSave) => {
        try {
            await SaveWebFontSize(fontSizeToSave)
        } catch (error) {
            await LogErrors('ChangeFontSize - Header', error)
        }
    }

    const Logout = async () => {
        try {
            const response = await BLLogout(token)

            if (response.success) {
                navigate("/")
            } else {
                //Mostrar mensaje de que no se pudo cerrar la sesión correctamente. Intenta nuevamente o más tarde.
                //En un componente de mensajes
            }
        } catch (error) {
            await LogErrors('Logout - Header', error)
        }
    }


    return (
        <header className="container Header">
            <div className="Header-Side1">
                <div className="Header-Brand">
                    {theTheme === 'dark' ? (
                        <img src="./img/brand/Ztudia-Logotipo-Blanco-66x26.png" className="Header-Image" />
                    ) : (
                        <img src="./img/brand/Ztudia-Logotipo-Negro-66x26.png" className="Header-Image" />
                    )}
                </div>
            </div>

            {text !== '' && (
                <div className="Header-Side2">
                    <p className="Header-Text">{text}</p>
                </div>
            )}

            <div className="Header-Side3">
                <div className="Header-Menu btn-group">
                    <button type="button" className="dropdown-toggle Header-Menu-Button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-person-circle"></i>
                    </button>
                    
                    <ul className="dropdown-menu dropdown-menu-end Header-Menu-Options">
                        <li>
                            {theTheme === 'dark' ? (
                                <button className="dropdown-item" type="button" onClick={() => ChangeTheme('light')}>
                                    <i className="bi bi-sun"></i> {translate('labels.header.themes.light')}
                                </button>
                            ) : (
                                <button className="dropdown-item" type="button" onClick={() => ChangeTheme('dark')}>
                                    <i className="bi bi-moon"></i> {translate('labels.header.themes.dark')}
                                </button>
                            )}
                        </li>
                        <li className="dropdown-item Header-Menu-Options-Size">
                            <span className="Header-Menu-Options-Size-Small" onClick={() => ChangeFontSize("SmallFont")}>A</span>
                            <span className="Header-Menu-Options-Size-Medium" onClick={() => ChangeFontSize("MediumFont")}>A</span>
                            <span className="Header-Menu-Options-Size-Big" onClick={() => ChangeFontSize("BigFont")}>A</span>
                        </li>
                        <li>
                            <button className="dropdown-item" type="button" onClick={() => Logout()}><i className="bi bi-logout"></i> {translate('labels.header.logout')}</button>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export { Header };