import { LogErrors } from './../../LogErrors'


/**
 * Realiza la llamada a la API para obtener los cursos del usuario
 * @returns {Object} Resultado del proceso { success: boolean, data: Object, message: string }
 */
const GetCourses = async (token, centerSelected, roleSelected) => {
  try {
    const dataToSend = {
      "user": {
        "center": {
          "id": centerSelected
        },
        "role": {
          "id": roleSelected
        }
      }
    }

    const response = await fetch('https://ztudia.xyz/api/Api/ZtudianteMyCourses', { // Reemplaza "X" con la URL de tu API
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(dataToSend),
    })

    if (!response.ok) {
      // Manejar errores HTTP
      const errorData = await response.json()
      return { success: false, message: errorData.errorCode }
    }

    const data = await response.json()

    // Retornar la respuesta exitosa
    return { success: true, data: data }

    



    //OLD
    // const settings = await GetSettings()

    // if (settings && settings.courses) {
    //   const courses = settings.courses.map((course, index) => {
    //     return {
    //       type: 'course',
    //       indexCourse: index,
    //       tileIconClass: course.tileIconClass,
    //       typeCourse: course.typeCourse,
    //       nameCourse: course.nameCourse,
    //       percentageCompleted: course.percentageCompleted
    //     }
    //   })

    //   return courses
    // } else {
    //   return null
    // }

    // const coursesData = {
    //   "myCourses": [
    //       {
    //           "id": "1",
    //           "name": "Hojas de Cálculo",
    //           "type": 1,
    //           "level": 1,
    //           "hasQuickAccess": true
    //       },
    //       {
    //           "id": "2",
    //           "name": "Procesadores de texto",
    //           "type": 1,
    //           "level": 1,
    //           "hasQuickAccess": false
    //       }
    //   ],
    //   "myApprovedCourses": [
    //       {
    //           "id": "1",
    //           "name": "Resultado 1",
    //           "type": 1,
    //           "level": 1
    //       },
    //       {
    //           "id": "2",
    //           "name": "Resultado 2",
    //           "type": 2,
    //           "level": 1
    //       }
    //   ]
    // }

    // return { success: true, data: coursesData }
  } catch (error) {
    await LogErrors('GetCourses - Courses DL', error)
    //return { success: false, message: 'errorNetwork' } // Código genérico para errores de red
    return { success: false, message: 2 }
  }
}

const GetChoosenCourse = async (token, idCourse) => {
  try {
    const dataToSend = {
      "course": {
        "id": idCourse
      }
    }
    // Llamado a la API

    const response = await fetch("https://ztudia.xyz/api/Api/ChosenCourse", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(dataToSend)
    })

    if (!response.ok) {
        // Manejar errores HTTP
        const errorData = await response.json()
        //return { success: false, message: errorData.message || 'Error de autenticación' }
        return { success: false, message: errorData.errorCode }
    }

    const data = await response.json()

    // Retornar la respuesta exitosa
    return { success: true, data: data }



    // const choosenCourseData = {
    //   "objective": "Al finalizar el curso, el Ztudiante será capaz de demostrar un conocimiento profundo de las herramientas esenciales en hojas de cálculo. Aplicará estos conocimientos para organizar, analizar, visualizar y optimizar datos. Además, el Ztudiante desarrollará una actitud proactiva y ética hacia la gestión y manipulación de datos, promoviendo la responsabilidad y la toma de decisiones fundamentadas para situaciones académicas y profesionales.",
    //   "blocks": [
    //       {
    //           "id": 1,
    //           "name": "Evaluación diagnóstica de hojas de cálculo",
    //           "objective": "Esta evaluación permitirá que el Ztudiante demuestre su nivel de comprensión, conocimientos y destrezas iniciales en hojas de cálculo, incluyendo la interacción con celdas, filas y columnas, la creación y manejo de fórmulas básicas y avanzadas, la organización y presentación de datos y la creación de gráficas. Identificará sus fortalezas y áreas de mejora en estos bloques de conocimiento para facilitar una instrucción personalizada y adaptada a sus necesidades. Además, esta evaluación promoverá en el Ztudiante una actitud reflexiva, curiosa, proactiva e innovadora hacia el aprendizaje.",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 1,
    //                   "name": "Evaluación diagnóstica",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 2,
    //           "name": "Interacción con hojas de cálculo",
    //           "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de demostrar un conocimiento sólido sobre las herramientas de las hojas de cálculo, incluyendo navegar, seleccionar, modificar y organizar hojas. Aplicará estas habilidades para realizar dichas tareas de manera eficiente y precisa. Además, el Ztudiante desarrollará cualidades de proactividad, responsabilidad y ética en el uso de las herramientas de hojas de cálculo y en la gestión de la información.",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 2,
    //                   "name": "Abrir una hoja de cálculo",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 3,
    //                   "name": "Renombrar una hoja de cálculo",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 4,
    //                   "name": "Aplicar un color a la pestaña",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 5,
    //                   "name": "Modificar el color de pestaña",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 6,
    //                   "name": "Agregar hojas de cálculo",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 7,
    //                   "name": "Eliminar hojas de cálculo",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 8,
    //                   "name": "Ocultar una hoja de cálculo",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 9,
    //                   "name": "Mostrar una hoja de cálculo oculta",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 10,
    //                   "name": "Crear copias de una hoja de cálculo",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 11,
    //                   "name": "Cambiar la posición de una hoja de cálculo",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 12,
    //                   "name": "Actividad de Interacción con hojas de cálculo",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 13,
    //                   "name": "Evaluación parcial de Interacción con hojas de cálculo",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 3,
    //           "name": "Interacción con filas",
    //           "objective": "Aquí va el objetivo de Interacción con filas",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 14,
    //                   "name": "Insertar filas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 15,
    //                   "name": "Eliminar filas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 16,
    //                   "name": "Copiar filas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 17,
    //                   "name": "Cambiar la posición de las filas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 18,
    //                   "name": "Ocultar filas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 19,
    //                   "name": "Mostrar filas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 20,
    //                   "name": "Ajustar manualmente el alto de una fila",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 21,
    //                   "name": "Autoajustar el alto de una fila",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 22,
    //                   "name": "Actividad de Interacción con filas",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 23,
    //                   "name": "Evaluación parcial de Interacción con filas",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 4,
    //           "name": "Interacción con columnas",
    //           "objective": "Aquí va el objetivo de Interacción con columnas",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 24,
    //                   "name": "Insertar columnas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 25,
    //                   "name": "Eliminar columnas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 26,
    //                   "name": "Copiar columnas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 27,
    //                   "name": "Cambiar la posición de las columnas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 28,
    //                   "name": "Ocultar columnas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 29,
    //                   "name": "Mostrar columnas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 30,
    //                   "name": "Ajustar manualmente el ancho de una columna",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 31,
    //                   "name": "Autoajustar el ancho de una columna",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 32,
    //                   "name": "Actividad de Interacción con columnas",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 33,
    //                   "name": "Evaluación parcial de Interacción con columnas",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 5,
    //           "name": "Interacción esencial con celdas",
    //           "objective": "Aquí va el objetivo de Interacción esencial con celdas",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 34,
    //                   "name": "Escribir texto en una celda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 35,
    //                   "name": "Alinear horizontalmente el contenido de una celda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 36,
    //                   "name": "Alinear verticalmente el contenido de una celda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 37,
    //                   "name": "Copiar el contenido de una celda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 38,
    //                   "name": "Mover el contenido de una celda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 39,
    //                   "name": "Pegar los valores de una celda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 40,
    //                   "name": "Aplicar relleno a las celdas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 41,
    //                   "name": "Modificar el relleno de las celdas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 42,
    //                   "name": "Activar los bordes de celda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 43,
    //                   "name": "Actividad de Interacción esencial con celdas",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 44,
    //                   "name": "Evaluación parcial de Interacción esencial con celdas",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 6,
    //           "name": "Interacción avanzada con celdas",
    //           "objective": "Aquí va el objetivo de Interacción avanzada con celdas",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 45,
    //                   "name": "Combinar varias celdas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 46,
    //                   "name": "Separar las celdas combinadas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 47,
    //                   "name": "Copiar los formatos de una celda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 48,
    //                   "name": "Borrar los formatos de una celda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 49,
    //                   "name": "Borrar el contenido de una celda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 50,
    //                   "name": "Rellenar un rango de celdas con los días de la semana",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 51,
    //                   "name": "Rellenar un rango de celdas con los meses del año",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 52,
    //                   "name": "Insertar celdas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 53,
    //                   "name": "Eliminar celdas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 54,
    //                   "name": "Actividad de Interacción avanzada con celdas",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 55,
    //                   "name": "Evaluación parcial de Interacción avanzada con celdas",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 7,
    //           "name": "Formateo de contenido",
    //           "objective": "Aquí va el objetivo de Formateo de contenido",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 56,
    //                   "name": "Cambiar la fuente del texto",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 57,
    //                   "name": "Cambiar el tamaño de fuente del texto",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 58,
    //                   "name": "Cambiar el color de fuente",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 59,
    //                   "name": "Aplicar estilo de millares",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 60,
    //                   "name": "Aplicar estilo porcentual",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 61,
    //                   "name": "Aplicar formato de moneda",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 62,
    //                   "name": "Agregar un hipervínculo",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 63,
    //                   "name": "Modificar la URL de un hipervínculo",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 64,
    //                   "name": "Eliminar el hipervínculo de un texto",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 65,
    //                   "name": "Editar las reglas de formato condicional",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 66,
    //                   "name": "Actividad de Formateo de contenido",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 67,
    //                   "name": "Evaluación parcial de Formateo de contenido",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 8,
    //           "name": "Presentación de la hoja de cálculo",
    //           "objective": "Aquí va el objetivo de Presentación de la hoja de cálculo",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 68,
    //                   "name": "Eliminar comentarios",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 69,
    //                   "name": "Buscar y reemplazar texto",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 70,
    //                   "name": "Corregir la ortografìa",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 71,
    //                   "name": "Mostrar las líneas de la cuadrícula",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 72,
    //                   "name": "Ocultar las líneas de la cuadrícula",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 73,
    //                   "name": "Cambiar los colores del tema utilizado",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 74,
    //                   "name": "Inmovilizar la fila superior",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 75,
    //                   "name": "Inmovilizar la primera columna",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 76,
    //                   "name": "Movilizar los paneles inmóviles",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 77,
    //                   "name": "Mostrar fórmulas en lugar de resultados",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 78,
    //                   "name": "Ajustar el nivel de zoom a un valor específico",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 79,
    //                   "name": "Actividad de Presentación de la hoja de cálculo",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 80,
    //                   "name": "Evaluación parcial de Presentación de la hoja de cálculo",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 9,
    //           "name": "Creación de fórmulas esenciales",
    //           "objective": "Aquí va el objetivo de Creación de fórmulas esenciales",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 81,
    //                   "name": "Crear fórmulas con operadores aritméticos",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 82,
    //                   "name": "Crear fórmulas con jerarquía de operadores",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 83,
    //                   "name": "Crear fórmulas con referencias 3D",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 84,
    //                   "name": "Modificar fórmulas para recalcular un resultado",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 85,
    //                   "name": "Copiar fórmulas que contienen referencias de celda relativas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 86,
    //                   "name": "Crear fórmulas con referencias de columna absoluta",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 87,
    //                   "name": "Crear fórmulas con referencias de fila absoluta",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 88,
    //                   "name": "Crear fórmulas con referencias absolutas totales",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 89,
    //                   "name": "Copiar fórmulas que contienen referencias de celda absolutas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 90,
    //                   "name": "Convertir las referencias relativas a referencias de columna absoluta",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 91,
    //                   "name": "Convertir las referencias relativas a referencias de fila absoluta",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 92,
    //                   "name": "Convertir las referencias relativas a referencias absolutas totales",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 93,
    //                   "name": "Actividad de Creación de fórmulas esenciales",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 94,
    //                   "name": "Evaluación parcial de Creación de fórmulas esenciales",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 10,
    //           "name": "Creación de fórmulas con funciones básicas",
    //           "objective": "Aquí va el objetivo de Creación de fórmulas con funciones básicas",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 95,
    //                   "name": "Contar las celdas que están vacías",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 96,
    //                   "name": "Contar las celdas que no están vacías",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 97,
    //                   "name": "Contar las celdas que tienen números",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 98,
    //                   "name": "Contar las celdas que cumplen una condición",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 99,
    //                   "name": "Convertir las unidades de un sistema de medición a otro",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 100,
    //                   "name": "Calcular la suma de un rango de celdas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 101,
    //                   "name": "Calcular el promedio de un rango de celdas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 102,
    //                   "name": "Obtener el valor más grande en un rango de celdas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 103,
    //                   "name": "Obtener el valor más pequeño en un rango de celdas",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 103,
    //                   "name": "Actividad de Creación de fórmulas con funciones básicas",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 104,
    //                   "name": "Evaluación parcial de Creación de fórmulas con funciones básicas",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 11,
    //           "name": "Creación de fórmulas con funciones avanzadas",
    //           "objective": "Aquí va el objetivo de Creación de fórmulas con funciones avanzadas",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 105,
    //                   "name": "Obtener el valor más grande a partir de condiciones",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 106,
    //                   "name": "Obtener el valor más pequeño a partir de condiciones",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 107,
    //                   "name": "Mostrar valores específicos a partir de varias condiciones",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 108,
    //                   "name": "Mostrar la fecha actual",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 109,
    //                   "name": "Mostrar la fecha y hora actuales",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 110,
    //                   "name": "Concatenar información mediante operadores",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 111,
    //                   "name": "Sustituir un texto específico por otro",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 112,
    //                   "name": "Mostrar un texto con formato de nombre propio",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 113,
    //                   "name": "Actividad de Creación de fórmulas con funciones avanzadas",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 114,
    //                   "name": "Evaluación parcial de Creación de fórmulas con funciones avanzadas",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 12,
    //           "name": "Organización esencial de datos",
    //           "objective": "Aquí va el objetivo de Organización esencial de datos",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 115,
    //                   "name": "Establecer nombres de rango",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 116,
    //                   "name": "Eliminar duplicados",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 117,
    //                   "name": "Habilitar los filtros de información",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 118,
    //                   "name": "Filtrar información por valores específicos",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 119,
    //                   "name": "Filtrar información por color de fuente",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 120,
    //                   "name": "Filtrar información por color de relleno",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 121,
    //                   "name": "Actualizar los filtros aplicados",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 122,
    //                   "name": "Borrar los filtros aplicados",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 123,
    //                   "name": "Actividad de Organización esencial de datos",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 124,
    //                   "name": "Evaluación parcial de Organización esencial de datos",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 13,
    //           "name": "Organización avanzada de datos",
    //           "objective": "Aquí va el objetivo de Organización avanzada de datos",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 125,
    //                   "name": "Ordenar información",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 126,
    //                   "name": "Ordenar información por múltiples criterios",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 127,
    //                   "name": "Ordenar información por color de fuente",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 128,
    //                   "name": "Ordenar información por color de relleno",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 129,
    //                   "name": "Aplicar formato de tabla a un rango de datos",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 130,
    //                   "name": "Renombrar una tabla",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 131,
    //                   "name": "Modificar el tamaño original de una tabla",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 132,
    //                   "name": "Eliminar el formato de tabla",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 133,
    //                   "name": "Actividad de Organización avanzada de datos",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 134,
    //                   "name": "Evaluación parcial de Organización avanzada de datos",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 14,
    //           "name": "Graficación de datos",
    //           "objective": "Aquí va el objetivo de Graficación de datos",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 135,
    //                   "name": "Crear una gráfica",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 136,
    //                   "name": "Cambiar el tipo de la gráfica actual",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 137,
    //                   "name": "Cambiar el ancho de una gráfica",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 138,
    //                   "name": "Cambiar el alto de una gráfica",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 139,
    //                   "name": "Actualizar el origen de datos de una gráfica",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 140,
    //                   "name": "Cambiar el valor mínimo en el eje vertical de una gráfica",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 141,
    //                   "name": "Cambiar el valor máximo en el eje vertical de una gráfica",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 142,
    //                   "name": "Mover una gráfica a otra hoja",
    //                   "type": 2,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 143,
    //                   "name": "Actividad de Graficación de datos",
    //                   "type": 3,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               },
    //               {
    //                   "id": 144,
    //                   "name": "Evaluación parcial de Graficación de datos",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 15,
    //           "name": "Evaluación general de hojas de cálculo",
    //           "objective": "Medir el avance de los conocimientos en hojas de cálculo",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 145,
    //                   "name": "Evaluación general",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 16,
    //           "name": "Evaluación de acreditación de hojas de cálculo",
    //           "objective": "Esta evaluación te permite alcanzar la acreditación de hojas de cálculo",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 146,
    //                   "name": "Evaluación final de acreditación",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       },
    //       {
    //           "id": 17,
    //           "name": "Evaluación final de hojas de cálculo",
    //           "objective": "Esta evaluación te permite alcanzar la certificación de hojas de cálculo",
    //           "percentage": null,
    //           "hasResults": false,
    //           "items": [
    //               {
    //                   "id": 147,
    //                   "name": "Evaluación final de certificación",
    //                   "type": 1,
    //                   "evaluation": null,
    //                   "competence": null,
    //                   "activity": null
    //               }
    //           ]
    //       }
    //   ],
    //   "quickAccess": {
    //       "id": 1
    //   }
    // }

    // return {
    //     success: true,
    //     data: {
    //         "objective": "Al finalizar el curso, el Ztudiante será capaz de demostrar un conocimiento profundo de las herramientas esenciales en hojas de cálculo. Aplicará estos conocimientos para organizar, analizar, visualizar y optimizar datos. Además, el Ztudiante desarrollará una actitud proactiva y ética hacia la gestión y manipulación de datos, promoviendo la responsabilidad y la toma de decisiones fundamentadas para situaciones académicas y profesionales.",
    //         "blocks": [
    //             {
    //                 "id": 1,
    //                 "name": "Evaluación diagnóstica",
    //                 "objective": "Esta evaluación permitirá que el Ztudiante demuestre su nivel de comprensión, conocimientos y destrezas iniciales en hojas de cálculo, incluyendo la interacción con celdas, filas y columnas, la creación y manejo de fórmulas básicas y avanzadas, la organización y presentación de datos y la creación de gráficas. Identificará sus fortalezas y áreas de mejora en estos bloques de conocimiento para facilitar una instrucción personalizada y adaptada a sus necesidades. Además, esta evaluación promoverá en el Ztudiante una actitud reflexiva, curiosa, proactiva e innovadora hacia el aprendizaje.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 1,
    //                         "name": "Evaluación diagnóstica",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 2,
    //                 "name": "Interacción con hojas de cálculo",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de demostrar un conocimiento sólido sobre las herramientas de las hojas de cálculo, incluyendo navegar, seleccionar, modificar y organizar hojas. Aplicará estas habilidades para realizar dichas tareas de manera eficiente y precisa. Además, el Ztudiante desarrollará cualidades de proactividad, responsabilidad y ética en el uso de las herramientas de hojas de cálculo y en la gestión de la información.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 2,
    //                         "name": "Abrir una hoja de cálculo",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 3,
    //                         "name": "Renombrar una hoja de cálculo",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 4,
    //                         "name": "Aplicar un color a la pestaña",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 5,
    //                         "name": "Modificar el color de pestaña",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 6,
    //                         "name": "Agregar hojas de cálculo",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 7,
    //                         "name": "Eliminar hojas de cálculo",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 8,
    //                         "name": "Ocultar una hoja de cálculo",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 9,
    //                         "name": "Mostrar una hoja de cálculo oculta",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 10,
    //                         "name": "Crear copias de una hoja de cálculo",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 11,
    //                         "name": "Cambiar la posición de una hoja de cálculo",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 12,
    //                         "name": "Actividad 1 - Interacción con hojas de cálculo",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 13,
    //                         "name": "Evaluación parcial de Interacción con hojas de cálculo",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 3,
    //                 "name": "Interacción con filas",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de utilizar de forma adecuada las herramientas esenciales relacionadas con filas en hojas de cálculo, como insertar, eliminar, copiar, cambiar de posición, ocultar, mostrar y ajustar el alto de las filas. Comprenderá la importancia de estas acciones para el manejo ordenado de los datos. Además, el Ztudiante desarrollará cualidades de precisión, responsabilidad y ética en la organización y gestión de la información.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 14,
    //                         "name": "Insertar filas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 15,
    //                         "name": "Eliminar filas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 16,
    //                         "name": "Copiar filas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 17,
    //                         "name": "Cambiar la posición de las filas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 18,
    //                         "name": "Ocultar filas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 19,
    //                         "name": "Mostrar filas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 20,
    //                         "name": "Ajustar manualmente el alto de una fila",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 21,
    //                         "name": "Autoajustar el alto de una fila",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 22,
    //                         "name": "Actividad 2 - Interacción con filas",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 23,
    //                         "name": "Evaluación parcial de Interacción con filas",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 4,
    //                 "name": "Interacción con columnas",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de realizar diversas tareas en columnas, tales como insertar, eliminar, copiar, cambiar de posición, ocultar, mostrar y ajustar el ancho de las columnas. Entenderá la importancia de estas herramientas para mantener una estructura de datos coherente. Además, el Ztudiante fomentará una actitud de agilidad, responsabilidad y organización en la manipulación de columnas.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 24,
    //                         "name": "Insertar columnas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 25,
    //                         "name": "Eliminar columnas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 26,
    //                         "name": "Copiar columnas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 27,
    //                         "name": "Cambiar la posición de las columnas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 28,
    //                         "name": "Ocultar columnas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 29,
    //                         "name": "Mostrar columnas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 30,
    //                         "name": "Ajustar manualmente el ancho de una columna",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 31,
    //                         "name": "Autoajustar el ancho de una columna",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 32,
    //                         "name": "Actividad 3 - Interacción con columnas",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 33,
    //                         "name": "Evaluación parcial de Interacción con columnas",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 5,
    //                 "name": "Interacción esencial con celdas",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de realizar tareas esenciales con celdas, como escribir, copiar, mover contenido, alinear y aplicar formato, incluyendo rellenos y bordes. Comprenderá la importancia de la alineación y el formato de celdas para una organización efectiva y clara de los datos. Además, el Ztudiante adoptará una actitud de responsabilidad y atención al detalle en la gestión de datos, asegurando una manipulación ética de la información.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 34,
    //                         "name": "Escribir texto en una celda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 35,
    //                         "name": "Alinear horizontalmente el contenido de una celda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 36,
    //                         "name": "Alinear verticalmente el contenido de una celda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 37,
    //                         "name": "Copiar el contenido de una celda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 38,
    //                         "name": "Mover el contenido de una celda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 39,
    //                         "name": "Pegar los valores de una celda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 40,
    //                         "name": "Aplicar relleno a las celdas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 41,
    //                         "name": "Modificar el relleno de las celdas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 42,
    //                         "name": "Activar los bordes de celda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 43,
    //                         "name": "Actividad 4 - Interacción esencial con celdas",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 44,
    //                         "name": "Evaluación parcial de Interacción esencial con celdas",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 6,
    //                 "name": "Interacción avanzada con celdas",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de elaborar una variedad de tareas en celdas, como insertar, eliminar, combinar y separar celdas, copiar formatos, borrar contenido y rellenar rangos de celdas. Comprenderá la relevancia de estas herramientas para mantener una organización y relación de los datos. Además, el Ztudiante fomentará una actitud de agilidad, responsabilidad y ética en la manipulación de celdas.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 45,
    //                         "name": "Combinar varias celdas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 46,
    //                         "name": "Separar las celdas combinadas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 47,
    //                         "name": "Copiar los formatos de una celda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 48,
    //                         "name": "Borrar los formatos de una celda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 49,
    //                         "name": "Borrar el contenido de una celda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 50,
    //                         "name": "Rellenar un rango de celdas con los días de la semana",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 51,
    //                         "name": "Rellenar un rango de celdas con los meses del año",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 52,
    //                         "name": "Insertar celdas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 53,
    //                         "name": "Eliminar celdas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 54,
    //                         "name": "Actividad 5 - Interacción avanzada con celdas",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 55,
    //                         "name": "Evaluación parcial de Interacción avanzada con celdas",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 7,
    //                 "name": "Formateo de contenido",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de demostrar un conocimiento amplio en el formateo de contenido en hojas de cálculo, tal como cambiar la fuente, tamaño y color de texto, aplicar formato de número, formato condicional y manipular hipervínculos. Utilizará estas herramientas para asegurar una visualización coherente y profesional de los datos. Además, el Ztudiante fomentará una actitud cuidadosa, ética y responsable en el formateo de contenido.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 56,
    //                         "name": "Cambiar la fuente del texto",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 57,
    //                         "name": "Cambiar el tamaño de fuente del texto",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 58,
    //                         "name": "Cambiar el color de fuente",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 59,
    //                         "name": "Aplicar estilo de millares",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 60,
    //                         "name": "Aplicar estilo porcentual",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 61,
    //                         "name": "Aplicar formato de moneda",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 62,
    //                         "name": "Agregar un hipervínculo",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 63,
    //                         "name": "Modificar la URL de un hipervínculo",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 64,
    //                         "name": "Eliminar el hipervínculo de un texto",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 65,
    //                         "name": "Editar las reglas de formato condicional",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 66,
    //                         "name": "Actividad 6 - Formateo de contenido",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 67,
    //                         "name": "Evaluación parcial de Formateo de contenido",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 8,
    //                 "name": "Presentación de la hoja de cálculo",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de realizar distintas tareas con la presentación de hojas de cálculo, como buscar y reemplazar texto, eliminar comentarios, corregir ortografía, mostrar fórmulas, cambiar los colores del tema, líneas de cuadrícula, ajustar el zoom e inmovilizar y movilizar paneles. Comprenderá la importancia de estas herramientas para optimizar la manipulación organizada de los datos. Además, el Ztudiante fomentará una actitud detallista, ética y responsable en la presentación de la información.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 68,
    //                         "name": "Eliminar comentarios",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 69,
    //                         "name": "Buscar y reemplazar texto",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 70,
    //                         "name": "Corregir la ortografìa",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 71,
    //                         "name": "Mostrar las líneas de la cuadrícula",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 72,
    //                         "name": "Ocultar las líneas de la cuadrícula",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 73,
    //                         "name": "Cambiar los colores del tema utilizado",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 74,
    //                         "name": "Inmovilizar la fila superior",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 75,
    //                         "name": "Inmovilizar la primera columna",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 76,
    //                         "name": "Movilizar los paneles inmóviles",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 77,
    //                         "name": "Mostrar fórmulas en lugar de resultados",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 78,
    //                         "name": "Ajustar el nivel de zoom a un valor específico",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 79,
    //                         "name": "Actividad 7 - Presentación de la hoja de cálculo",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 80,
    //                         "name": "Evaluación parcial de Presentación de la hoja de cálculo",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 9,
    //                 "name": "Creación de fórmulas esenciales",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de comprender y utilizar fórmulas esenciales en hojas de cálculo, como fórmulas con operadores aritméticos, jerarquía de operadores y referencias 3D, así como copiar y modificar fórmulas con referencias de celda relativas y absolutas. Conocerá la importancia de estas fórmulas para optimizar cálculos y análisis de datos. Además, el Ztudiante fomentará una actitud cuidadosa, ética y responsable en la creación y modificación de fórmulas.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 81,
    //                         "name": "Crear fórmulas con operadores aritméticos",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 82,
    //                         "name": "Crear fórmulas con jerarquía de operadores",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 83,
    //                         "name": "Crear fórmulas con referencias 3D",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 84,
    //                         "name": "Modificar fórmulas para recalcular un resultado",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 85,
    //                         "name": "Copiar fórmulas que contienen referencias de celda relativas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 86,
    //                         "name": "Crear fórmulas con referencias de columna absoluta",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 87,
    //                         "name": "Crear fórmulas con referencias de fila absoluta",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 88,
    //                         "name": "Crear fórmulas con referencias absolutas totales",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 89,
    //                         "name": "Copiar fórmulas que contienen referencias de celda absolutas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 90,
    //                         "name": "Convertir las referencias relativas a referencias de columna absoluta",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 91,
    //                         "name": "Convertir las referencias relativas a referencias de fila absoluta",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 92,
    //                         "name": "Convertir las referencias relativas a referencias absolutas totales",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 93,
    //                         "name": "Actividad 8 - Creación de fórmulas esenciales",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 94,
    //                         "name": "Evaluación parcial de Creación de fórmulas esenciales",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 10,
    //                 "name": "Creación de fórmulas con funciones básicas",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de entender y aplicar diversas funciones básicas en hojas de cálculo, para calcular la suma de un rango de celdas, el promedio, obtener el valor más grande o el más pequeño, contar celdas, contar celdas con números y con condiciones específicas y convertir unidades de medición. Entenderá la importancia de las funciones para mejorar el análisis de datos. Además, el Ztudiante fomentará una actitud analítica, ética y responsable en el uso de funciones básicas en hojas de cálculo.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 95,
    //                         "name": "Contar las celdas que están vacías",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 96,
    //                         "name": "Contar las celdas que no están vacías",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 97,
    //                         "name": "Contar las celdas que tienen números",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 98,
    //                         "name": "Contar las celdas que cumplen una condición",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 99,
    //                         "name": "Convertir las unidades de un sistema de medición a otro",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 100,
    //                         "name": "Calcular la suma de un rango de celdas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 101,
    //                         "name": "Calcular el promedio de un rango de celdas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 102,
    //                         "name": "Obtener el valor más grande en un rango de celdas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 103,
    //                         "name": "Obtener el valor más pequeño en un rango de celdas",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 103,
    //                         "name": "Actividad 9 - Creación de fórmulas con funciones básicas",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 104,
    //                         "name": "Evaluación parcial de Creación de fórmulas con funciones básicas",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 11,
    //                 "name": "Creación de fórmulas con funciones avanzadas",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de analizar y utilizar funciones avanzadas en hojas de cálculo, tales como mostrar la fecha y hora, concatenar información mediante operadores, sustituir un texto, mostrar un texto con formato de nombre propio y obtener valores específicos a partir de condiciones. Valorará la importancia de las funciones para mejorar la eficiencia en el análisis de datos. Además, el Ztudiante aplicará una actitud innovadora, ética y responsable en el uso de funciones avanzadas en hojas de cálculo.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 105,
    //                         "name": "Obtener el valor más grande a partir de condiciones",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 106,
    //                         "name": "Obtener el valor más pequeño a partir de condiciones",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 107,
    //                         "name": "Mostrar valores específicos a partir de varias condiciones",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 108,
    //                         "name": "Mostrar la fecha actual",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 109,
    //                         "name": "Mostrar la fecha y hora actuales",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 110,
    //                         "name": "Concatenar información mediante operadores",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 111,
    //                         "name": "Sustituir un texto específico por otro",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 112,
    //                         "name": "Mostrar un texto con formato de nombre propio",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 113,
    //                         "name": "Actividad 10 - Creación de fórmulas con funciones avanzadas",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 114,
    //                         "name": "Evaluación parcial de Creación de fórmulas con funciones avanzadas",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 12,
    //                 "name": "Organización esencial de datos",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de aplicar diversas herramientas para la organización esencial de datos en hojas de cálculo, tal como eliminar duplicados, establecer nombres de rango y trabajar con filtros de información. Comprenderá la importancia de estas herramientas para mantener tablas de datos limpias y organizadas. Además, el Ztudiante fomentará una actitud detallista y responsable para la gestión de datos.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 115,
    //                         "name": "Establecer nombres de rango",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 116,
    //                         "name": "Eliminar duplicados",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 117,
    //                         "name": "Habilitar los filtros de información",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 118,
    //                         "name": "Filtrar información por valores específicos",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 119,
    //                         "name": "Filtrar información por color de fuente",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 120,
    //                         "name": "Filtrar información por color de relleno",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 121,
    //                         "name": "Actualizar los filtros aplicados",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 122,
    //                         "name": "Borrar los filtros aplicados",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 123,
    //                         "name": "Actividad 11 - Organización esencial de datos",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 124,
    //                         "name": "Evaluación parcial de Organización esencial de datos",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 13,
    //                 "name": "Organización avanzada de datos",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de implementar habilidades avanzadas para la organización de datos en hojas de cálculo, tales como ordenar información, aplicar y eliminar formato de tabla, renombrar tablas y modificar el tamaño original de tablas. Entenderá la importancia de estas herramientas para optimizar la visualización y accesibilidad de la información. Además, el Ztudiante fomentará una cualidad cuidadosa, ética y responsable en la organización avanzada de datos.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 125,
    //                         "name": "Ordenar información",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 126,
    //                         "name": "Ordenar información por múltiples criterios",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 127,
    //                         "name": "Ordenar información por color de fuente",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 128,
    //                         "name": "Ordenar información por color de relleno",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 129,
    //                         "name": "Aplicar formato de tabla a un rango de datos",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 130,
    //                         "name": "Renombrar una tabla",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 131,
    //                         "name": "Modificar el tamaño original de una tabla",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 132,
    //                         "name": "Eliminar el formato de tabla",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 133,
    //                         "name": "Actividad 12 - Organización avanzada de datos",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 134,
    //                         "name": "Evaluación parcial de Organización avanzada de datos",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 14,
    //                 "name": "Graficación de datos",
    //                 "objective": "Al concluir el bloque de conocimientos, el Ztudiante será capaz de comprender y utilizar herramientas para la elaboración y preparación de gráficas en hojas de cálculo, desde crear gráficas, mover una gráfica, actualizar el origen de datos, hasta cambiar el tipo, las dimensiones y los valores del eje vertical de una gráfica. Entenderá la importancia de transmitir y comprender visualmente los datos. Además, el Ztudiante fomentará una actitud creativa y responsable en la representación gráfica de la información.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 135,
    //                         "name": "Crear una gráfica",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 136,
    //                         "name": "Cambiar el tipo de la gráfica actual",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 137,
    //                         "name": "Cambiar el ancho de una gráfica",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 138,
    //                         "name": "Cambiar el alto de una gráfica",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 139,
    //                         "name": "Actualizar el origen de datos de una gráfica",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 140,
    //                         "name": "Cambiar el valor mínimo en el eje vertical de una gráfica",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 141,
    //                         "name": "Cambiar el valor máximo en el eje vertical de una gráfica",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 142,
    //                         "name": "Mover una gráfica a otra hoja",
    //                         "type": 2
    //                     },
    //                     {
    //                         "id": 143,
    //                         "name": "Actividad 13 - Graficación de datos",
    //                         "type": 3
    //                     },
    //                     {
    //                         "id": 144,
    //                         "name": "Evaluación parcial de Graficación de datos",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 15,
    //                 "name": "Evaluación general de hojas de cálculo",
    //                 "objective": "Esta evaluación permitirá que el Ztudiante compruebe su nivel de comprensión, conocimientos y destrezas adquiridas en el curso, abarcando todos los bloques de conocimiento, como la interacción con celdas, filas y columnas, la creación y manejo de fórmulas básicas y avanzadas, la organización y presentación de datos, y la creación de gráficas. validará que posee las capacidades necesarias para aprobar el curso, aplicando sus habilidades en situaciones prácticas. Además, la evaluación fomentará una actitud crítica, entusiasta, proactiva e innovadora hacia el aprendizaje.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 145,
    //                         "name": "Evaluación general",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 16,
    //                 "name": "Evaluación de acreditación de hojas de cálculo",
    //                 "objective": "Esta evaluación permitirá que el Ztudiante demuestre y compruebe su dominio en el uso de hojas de cálculo, abarcando todas las áreas de conocimiento vistas a lo largo del curso. Validará y aplicará conocimientos y destrezas necesarias para solucionar eficazmente situaciones prácticas. Además, esta evaluación fomentará en el Ztudiante una actitud innovadora, segura, proactiva y ética en el uso de hojas de cálculo para la solución de problemas y el análisis de datos.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 146,
    //                         "name": "Evaluación final de acreditación",
    //                         "type": 1
    //                     }
    //                 ]
    //             },
    //             {
    //                 "id": 17,
    //                 "name": "Evaluación final de hojas de cálculo",
    //                 "objective": "Esta evaluación permitirá que el Ztudiante valide y certifique su dominio en el uso de hojas de cálculo, cubriendo todas las áreas de conocimiento vistas en el curso. Comprobará sus capacidades y destrezas mediante la aplicación práctica de habilidades en la creación de fórmulas, organización de datos y generación de gráficos, asegurando que cumple con estándares internacionales. Además, esta evaluación fomentará en el Ztudiante una actitud profesional, segura, proactiva e innovadora en el uso de hojas de cálculo para la solución de problemas complejos y el análisis de datos, con un fuerte compromiso ético en la gestión y manipulación de información.",
    //                 "percentage": "",
    //                 "items": [
    //                     {
    //                         "id": 147,
    //                         "name": "Evaluación de certificación",
    //                         "type": 1
    //                     }
    //                 ]
    //             }
    //         ],
    //         "quickAccess": {
    //             "id": 2
    //         }
    //     }
    // }
  } catch (error) {
    await LogErrors('GetChoosenCourse - Courses DL', error)
    return { success: false, message: 2 }
  }
}

export { GetCourses, GetChoosenCourse };