import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../../utilities/LogErrors'
import { useLanguage } from './../../../utilities/LanguageContext'

import { SaveCourseToShow } from './../../../utilities/BL/Navigation'


const ApprovedCourse = ({ element, session, setSession }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()


    const ShowResultsOfCourse = async () => {
        try {
            const response = await SaveCourseToShow(session, element)

            if (response.success) {
                setSession(response.data)
                navigate('/results')
            }
        } catch (error) {
            await LogErrors('ShowResultsOfCourse - ApprovedCourse', error)
            return { success: false, message: '' }
        }
    }

    return (
        <a className={'Tiles-ApprovedCourses'} onClick={ShowResultsOfCourse}>
            <div className='Tile-Info'>
                {/* <figure className='Tile-Figure'>
                    <i className='Tile-Icon bi bi-list-check'></i>
                </figure> */}
                
                <div className='Tile-ContainerText'>
                    <span className='Tile-Text'>{element.type === 1 ? translate('labels.dashboard.tiles.examType.certification') : translate('labels.dashboard.tiles.examType.custom')}</span>
                    <span className='Tile-Text'>{element.name}</span>
                    <span className='Tile-Text'><i className='bi bi-check-circle-fill'></i> {translate('labels.dashboard.tiles.approvedCourse.approvedLegend')}</span>
                </div>
            </div>

            {/* Botón */}
            {/* <i className='bi bi-box-arrow-up-right Tile-Icon-GoTo'></i> */}
        </a>
    )
}

export { ApprovedCourse };