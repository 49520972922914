import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../../utilities/LogErrors'
import { useLanguage } from './../../../utilities/LanguageContext'

import { SaveCourseToShow } from './../../../utilities/BL/Navigation'


const Course = ({ index, element, session, setSession }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()

    
    const ShowMoveIcon = (e) => {
        try {
            e.target.parentNode.classList.remove('HideMoveButton')
        } catch (error) {
            console.log(error)
        }
    }
    
    const HideMoveIcon = (e) => {
        try {
            e.target.parentNode.classList.add('HideMoveButton')
        } catch (error) {
            console.log(error)
        }
    }

    const GoToNextView = async (goToQuickAccessInstrument = false) => {
        try {
            const response = await SaveCourseToShow(session, element, goToQuickAccessInstrument)
            
            if (response.success) {
                setSession(response.data)
                navigate('/course')
            }
        } catch (error) {
            await LogErrors('GoToNextView - Course', error)
            return { success: false, message: '' }
        }
    }


    return (
        <div className={`Tiles-Courses ${index === 0 && 'ActiveTile'}`} onMouseOver={ShowMoveIcon} onMouseOut={HideMoveIcon}>
            <div className='Tile-Info' onClick={() => GoToNextView()}>
                {element.tileIconClass && element.tileIconClass !== '' && (
                    <figure className='Tile-Figure'>
                        <i className={`Tile-Icon ${element.tileIconClass}`}></i>
                    </figure>
                )}
                
                <div className='Tile-ContainerText'>
                    <span className='Tile-Text'>{element.type === 1 ? translate('labels.dashboard.tiles.examType.certification') : translate('labels.dashboard.tiles.examType.custom')}</span>
                    <span className='Tile-Text'>{element.name}</span>
                </div>
            </div>

            {element.hasQuickAccess && (
                <button type='button' className='Tile-Button-Highlight' onClick={() => GoToNextView(true)}>{translate('labels.dashboard.tiles.course.continueButton')}</button>
            )}

            <button type='button' className='Tile-Button-Transparent' onClick={() => GoToNextView()}>{translate('labels.dashboard.tiles.course.goToCourse')}</button>
        </div>
    )
}

export { Course };