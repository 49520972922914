const Options = ({ elements, textButton, setState }) => {
    try {
        return (
            <>
                <p>{textButton}</p>

                {elements !== undefined && elements.length > 0 && (
                    elements.map((element, index) => (
                        element && (
                            <button key={index} type="button" onClick={() => setState(index)}>{element.name ? element.name : element.id}</button>
                        )
                    ))
                )}
            </>
        )
    } catch (error) {
        
    }
}

export { Options };