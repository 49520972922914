import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//Funciones comunes
import { LogErrors } from './../../../utilities/LogErrors'
import { useLanguage } from './../../../utilities/LanguageContext'

//Componentes comunes
import { Loading } from './../../Common/Loading'
import { GoToBack } from './../../Common/GoToBack'


const Activity = ({ activityName, instrument }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()
    
    const [activity, setActivity] = useState({})
    const [isLoading, setIsLoading] = useState(true)


    const LoadPage = async () => {
        try {
            setActivity(instrument.activity)
        } catch (error) {
            await LogErrors('LoadPage - Activity', error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        LoadPage()
    },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar


    return (
        isLoading ? (
            <Loading />
        ) : (
            <main>
                <div className="Container">
                    <GoToBack url={'/course'} className={'mt-4'} />
                </div>

                <div className="Container Activity">
                    <div className="Activity-Instructions">
                        <p className="Activity-Instructions-Title">{activityName}</p>

                        <p className="" dangerouslySetInnerHTML={{ __html: activity.instructions }} />

                        {/* <p className="Activity-Instructions-Text">Descarga la hoja de cálculo de este ejercicio para poner en práctica lo aprendido. Realiza lo siguiente:</p>

                        {activity.steps.length > 0 && (
                            <ol className="Activity-Instructions-Steps">
                                {activity.steps[0].map((step, index) => (
                                    <li key={index} className="Activity-Instructions-Steps-Step">{step}</li>
                                ))}
                            </ol>
                        )}

                        <p className="Activity-Instructions-Text">Si tienes alguna duda sobre cómo desarrollar algunos de los puntos, puedes consultar los elementos de aprendizaje que se encuentran en cada uno de los temas.</p> */}
                    </div>

                    <div className="Activity-Files">
                        <p className="Activity-Files-Title">{translate('labels.viewer.activities.fileTitle')}</p>

                        <a className="Activity-Files-Download" href={activity.downloadFile} download>
                            {/* <img className="Activity-Files-Download-Image" src="/img/activity/excel.png" alt="Ícono de descarga" /> */}
                            <i class="bi bi-file-earmark-spreadsheet Activity-Files-Download-Image"></i>
                            <span className="Activity-Files-Download-Text"><i class="bi bi-download"></i> {translate('labels.viewer.activities.downloadText')}</span>
                        </a>
                    </div>
                </div>
            </main>
        )
    )
}

export { Activity };