import { useNavigate } from 'react-router-dom'


const GoToBack = ({ url, className }) => {
    const navigate = useNavigate()


    return (
        <button type='button' className={`Button-Return${className !== '' ? ' ' + className : ''}`} onClick={() => navigate(url)}><i className="bi bi-caret-left-fill"></i></button> // bi-arrow-90deg-left
    )
}

export { GoToBack };