import React, { useState } from 'react'


//Componentes comunes generales
import { Button } from './../Common/Button'


function Section({ textSection, classNameSection, dismiss, children}) {
    // Estado para controlar la clase del div
    const [divClass, setDivClass] = useState('ShowTileList')


    // Función para manejar el clic en el botón y alternar la clase del div
    const ShowOrHideSection = () => {
        setDivClass(prevClass => (prevClass === 'ShowTileList' ? 'HideTileList' : 'ShowTileList'))
    }



    return (
        <section className={`Section ${classNameSection}`}>
            {/* Botón mostrar u ocultar sección */}
            <Button type={'type'} className={'Section-Button'} iconButton={'bi bi-caret-down'} iconClass={'bi bi-three-dots-vertical'} text={textSection} onClick={ShowOrHideSection} />
            
            {/* Botón descartar */}
            {dismiss ? true && <Button type={'type'} className={"Section-Button-Dismiss"} iconClass={''} text={"Descartar"} /> : false}
            
            {/* Listado de Tiles de sección */}
            <div className={`TileList ${divClass}`}>
                {children}
            </div>
        </section>
    )
}

export { Section };