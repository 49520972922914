import { useState } from 'react'
import { useNavigate } from 'react-router-dom'


//Componentes personalizados del proyecto
import { Concepts } from './Concepts/Concepts'
import { Quizzes } from './Quizzes/Quizzes'
import { Videos } from './Videos/Videos'
import { Challenges } from './Challenges/Challenges'

//Componentes comunes generales
import { GoToTop } from './../../Common/GoToTop'
import { GoToBack } from './../../Common/GoToBack'


const Teaching = ({ session, setSession, instrument }) => {
    const navigate = useNavigate()

    const [activeComponent, setActiveComponent] = useState('Concept') // Estado para controlar el componente activo
    

    return (
        <>
            <main className='Container Container-FullHeight Teaching'>
                <GoToBack url={'/course'} />
                
                <div className='Teaching-Container'>
                    {activeComponent === 'Concept' && <Concepts topicName={session.navigation.instrument.name} instrument={instrument} /> }
                    {activeComponent === 'Quiz' && <Quizzes instrument={instrument} />}
                    {activeComponent === 'Video' && <Videos session={session} setSession={setSession} instrument={instrument} />}
                    {activeComponent === 'Challenge' && <Challenges instrument={instrument} />}
                </div>
            </main>

            <aside className='Teaching-Menu'>
                <div className='Teaching-Menu-Container'>
                    <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Concept' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Concept')}>Concepto</button>
                    <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Quiz' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Quiz')}>Cuestionario</button>
                    <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Video' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Video')}>Video</button>
                    <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Challenge' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Challenge')}>Desafío</button>
                </div>
            </aside>

            <GoToTop />
        </>
    )
}

export { Teaching };