import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../utilities/LogErrors'

import { ValidateSession } from './../../utilities/BL/Sessions'
import { GetLearningInstruments } from './../../utilities/BL/Student/Instruments'

//Componentes comunes generales
import { Loading } from './../Common/Loading'
import { Header } from './../Header/Header'

//Componentes personalizados del proyecto
import { Teaching } from './Teaching/Teaching'
import { Activity } from './Activity/Activity'
import { Exam } from './Exam/Exam'


const Viewer = () => {
    const navigate = useNavigate()
    
    const [activeComponent, setActiveComponent] = useState('')

    const [isLoading, setIsLoading] = useState(true)
    const [session, setSession] = useState({})
    const [instrument, setInstrument] = useState({})
    

    const LoadPage = async (sessionInfo) => {
        try {
            let response
            let component

            switch (sessionInfo.navigation.instrument.type) {
                case 1:
                    //response = await GetExam(sessionInfo)
                    component = 'exam'
                    break
                case 2:
                    response = await GetLearningInstruments(sessionInfo)
                    component = 'teachingMaterial'
                    break
                case 3:
                    response = await GetLearningInstruments(sessionInfo)
                    component = 'activity'
                    break
            }

            if (response.success) {
                setInstrument(response.data)
                setActiveComponent(component)
            } else {
                //Mostrar mensaje de que no se puedo obtener la info o el error
                console.log("No se pudo obtener la info: " + response.message) //Mensaje temporal
            }
        } catch (error) {
            await LogErrors('LoadPage - Viewer', error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        async function Validate() {
            const tmpSession = await ValidateSession()
            setSession(tmpSession.data)
    
            if (tmpSession.success) {
                if (tmpSession.data.user.isConfirmed) {
                    await LoadPage(tmpSession.data)
                } else {
                    navigate('/confirm')
                }
            } else {
                navigate('/')
            }
        }
        Validate()
    },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar


    return (
        isLoading ? (
            <Loading />
        ) : (
            <>
                <Header text={session.navigation.instrument.name} theme={session.user.theme} />

                {activeComponent.toLowerCase() === 'exam'.toLowerCase() && <Exam instrument={instrument} />}
                {activeComponent.toLowerCase() === 'teachingMaterial'.toLowerCase() && <Teaching session={session} setSession={setSession} instrument={instrument} />}
                {activeComponent.toLowerCase() === 'activity'.toLowerCase() && <Activity activityName={session.navigation.instrument.name} instrument={instrument} />}
            </>
        )
    )
}

export { Viewer };