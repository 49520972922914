import { LogErrors } from './../../LogErrors'
import { UpdateSessionInfo } from './../Sessions'

import { GetCourses as DLGetCourses, GetChoosenCourse as DLGetChoosenCourse } from './../../DL/Student/Courses'


//Poner multiidioma a los mensajes de error


const GetCourses = async (session, centerSelected, rolSelected) => {
    try {
      //Validar que exista la info del usuario en el session storage
      if (!sessionStorage.getItem('userData')) {
        return { success: false, message: '' } //En este caso borraron los datos; así que le muestro mensaje indicando que su sesión ha expirado.
      }
  
      //Obtener la info del usuario desde el session storage
      const userData = JSON.parse(sessionStorage.getItem('userData'))
      
      //Comprobar varias propiedades para ver si está correcta la info del usuario
      if (userData.token === undefined || userData.token === '') {
          return { success: false, message: '' } //Mensaje indicando que su sesión ha expirado.
      }

      //const response = await DLGetCourses(session.token, centerSelected, rolSelected)
      const response = await DLGetCourses(userData.token, centerSelected, rolSelected)
  
      if (response.success) {
        userData.user.courses = response.data
  
        const newData = await UpdateSessionInfo(userData)
        
        return { success: true, data: newData.data }
      } else {
        return { success: false, message: response.message }
      }
    } catch (error) {
      await LogErrors('GetCourses - BL Courses', error)
      return { success: false, message: '' }
    }
}

const GetChoosenCourse = async (session) => {
  try {
    // Por ahora lo obtengo directo de la API, la idea será que lo almacene también en el sessionStorage y lo obtenga de ahí para evitar muchos llamados a la API

    const response = await DLGetChoosenCourse(session.token, session.navigation.course.id)

    if (response.success) {
      // if (response.data.course.goToQuickAccessInstrument) {
      //   // Guardar el tema a mostrar en el Viewer
      //   session.navigation.topic = {
      //     id: response.data.quickAccess.id
      //   }
      // }

      // Guardar la info del curso en la propiedad en donde se irán poniendo todos los consultados
      session.courses = response.data

      // Actualizar información del usuario en la sesión
      await UpdateSessionInfo(session)

      // const newData = {
      //   "course": {
      //     "idCourse": session.navigation.course.id,
      //     "name": session.navigation.course.name,
      //     "type": session.navigation.course.type,
      //     "level": session.navigation.course.level,
      //     "goToQuickAccessInstrument": session.navigation.course.goToQuickAccessInstrument,
      //     "objective": response.data.objective,
      //     "quickAccess": response.data.quickAccess
      //   }
      // }

      // //console.log("newData: " + JSON.stringify(newData))

      // return {
      //   success: true,
      //   data: newData
      // }
      // //"blocks": response.data.blocks
      // //data: JSON.stringify(courseInfo)

      return { success: true, data: response.data }
    } else {
      return { success: false, message: response.message }
    }
  } catch (error) {
    await LogErrors('GetChoosenCourse - BL Courses', error)
    return { success: false, message: '' }
  }
}

const ResetChoosenCourse = async (session) => {
  try {
    if (session.courses === undefined) {
      return { success: true, data: session }
    } else {
      session.courses = null

      // Actualizar información del usuario en la sesión
      const response = await UpdateSessionInfo(session)
      
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Hubo un error al intentar cargar la información del curso. Inténtalo nuevamente o inténtalo más tarde.' }
      }
    }
  } catch (error) {
    await LogErrors('ResetChoosenCourse - BL Courses', error)
    return { success: false, message: '' }
  }
}

export { GetCourses, GetChoosenCourse, ResetChoosenCourse };