import { LogErrors } from './../../LogErrors'

import { UpdateSessionInfo } from './../Sessions'


//Agregar el multiidioma del mensaje
//Por ahora se guarda la preferencia en local, falta ver si se agregará en BD


const SavePreferenceVideo = async (session, orientation) => {
    try {
        session.settings = {
            "video": {
                "showVertical": orientation
            }
        }

        const response = await UpdateSessionInfo(session)

        if (response.success) {
            return { success: true, data: response.data }
        } else {
            return { success: false, message: 'No se pudo guardar la preferencia predeterminada en la que se mostrarán los videos' }
        }

        // const response = await SavePreferenceVideo(orientation)

        // if (response.success) {
        //     session.settings.video.showVertical = orientation

        //     const newData = await UpdateSessionInfo(session)

        //     return { success: true, data: newData }
        // } else {
        //     return { success: false, message: response.message }
        // }
    } catch (error) {
        await LogErrors('Student BL SavePreferenceVideo', error)
    }
}

export { SavePreferenceVideo };